import React from 'react';
import './index.scss';

function Info() {
  return (
    <div className="section info" id="info">
        <div className="anchor-wrapper">
            <div className="anchor"/>
        </div>
        <div className="inner">
            <h1>info</h1>
            <div className="content">
                Hello.<br/>My name is Troels.<br/>
                I help companies, people and agencies make things come alive using code. Primarily for the web.<br/>
                Mostly working with technologies and tools such as HTML, CSS, React.js, Wordpress, Cycling Max i.a.<br/>
                Coded my first website back in the 90s. Been in the game ever since.<br/>
            </div>
        </div>
    </div>
  );
}

export default Info;
