const initialState = {
	scrollPosition: 0,
	scrollDest: null,
	scrollIsAnimating: false
}

const App = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}
		break;

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: window.pageYOffset,
			}
		break;

		default:
			return state
	}
}

export default App