import React from 'react';
import './index.scss';

function Case() {
  return (
    <div>
    	Case
    </div>
  );
}

export default Case;
