import React from 'react';
import './index.scss';

function Contact() {
  return (
    <div className="section contact" id="contact">
        <div className="anchor-wrapper">
            <div className="anchor"/>
        </div>
        <div className="inner">
            <h1>contact</h1>
            <div className="content">
                mail: <a href="mailto:mail@troelsknud.dk">mail@troelsknud.dk</a><br/>
                phone: +45 28580628
            </div>
        </div>
    </div>
  );
}

export default Contact;
