import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './js/App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'
import {store, history} from './js/config/configureStore'


ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<React.StrictMode>
			    <App />
			  </React.StrictMode>
		</ConnectedRouter>
	</Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
