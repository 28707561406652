import React from 'react';
import './index.scss';
import {connect} from 'react-redux'

import AnimatedScrollHandler from './../AnimatedScrollHandler'

function Nav(props) {

	const menuItems = ['work', 'clients', 'info', 'contact']

	const menuClick = (e) => {
		const ele = document.getElementById(e);
		const scrollDest = ele.offsetTop-80;

		props.dispatch({type: 'RESET_ANIMATED_SCROLL'})

	    setTimeout(() => {
	        props.dispatch({type: 'SET_SCROLL_DEST', scrollDest: scrollDest})
	    }, 10)
	}

  return (
    <div className="nav col">
		<div className="hero">
			<div className="logo">
                <div className="name">troels<br/>knud</div>
                <div className="byline">buildingwithcode</div>
            </div>
            <ul className="menu">
	            {menuItems.map((ele, id) => (
	        		<li key={id} onClick={() => menuClick(ele)}>{ele}</li>
	            ))}
            </ul>
		</div>
		<AnimatedScrollHandler/>
	</div>
  );
}

export default connect()(Nav);
