import React from 'react';
import './index.scss';

import Video2017 from './../../media/2017.mp4';
import VideoRodeo from './../../media/rodeo.mp4';
import VideoTinderbox from './../../media/tinderbox.mp4';

const cases = [
	{
		video: Video2017,
		title: 'Aarhus 2017',
		data: 'Interactive data visualization tool, displaying all events during the year-long cultural events. <br/>Made in collaboration with Hele Vejen.'
	},
	{
		video: VideoRodeo,
		title: 'Rodeo Architects',
		data: 'Full fleshed portfolio site for the norwegian architecture firm.<br/>Design by bwd.studio.'
	},
	{
		video: VideoTinderbox,
		title: 'Tinderbox Festival',
		data: 'Complete site for one of the major music festivals in Denmark, Tinderbox Festival. Showcasing artists, info, schedule, news etc.<br/>Design by Hele Vejen.'
	}
]

const render2017 = () => {
	return (
		<div className="case">
            <video autoPlay src={Video2017} muted loop/>
            <div className="title">Aarhus 2017</div>
            <div className="data">

            </div>
        </div>
		)
}

function Work() {
  return (
    <div className="section work" id="work">
        <div className="anchor-wrapper">
            <div className="anchor"/>
        </div>
        <div className="inner">
            <h1 class="headline">selected work</h1>
            <div className="content">
            	{cases.map((ele, i) => (
	                <div className="case" key={i}>
	                    <video autoPlay src={ele.video} muted loop/>
	                    <div className="title">{ele.title}</div>
	                    <div className="data" dangerouslySetInnerHTML={{__html:ele.data}}/>
	                </div>
        		))}
            </div>
        </div>
    </div>
  );
}

export default Work;
