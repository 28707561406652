import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './../reducers'

export const history = createBrowserHistory()

export function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history), 
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history)
      ),
    ),
  )

  return store
}

export const store = configureStore();