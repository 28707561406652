import React from 'react';
import './App.scss';

import Case from './Case'
import Nav from './Nav'
import Clients from './Clients'
import Work from './Work'
import Contact from './Contact'
import Info from './Info'

function App() {
  return (
    <div className="App">
    	<Nav/>
    	<div className="main col">
    		<Work/>
            <Clients/>
            <Info/>
            <Contact/>
    	</div>
    </div>
  );
}

export default App;
