import React from 'react';
import './index.scss';

function Projects() {
  return (
    <div className="section clients" id="clients">
        <div className="anchor-wrapper">
            <div className="anchor"/>
        </div>
        <div className="inner">
            <h1>clients & collaborators</h1>
            <div className="content">
                <ul className="clientlist">
                    <li>Horsens Kommune</li>
                    <li>Gentofte Kommune</li>
                    <li>Tinderbox Festival</li>
                    <li>Northside Festival</li>
                    <li>Roskilde Festival</li>
                    <li>Aarhus European Capital of Cultur 2017</li>
                    <li>Rodeo Architects</li>
                    <li>PreSeed Ventures</li>
                    <li>Bwd Studio</li>
                    <li>Kvindemuseet Aarhus</li>
                    <li>Creativity World Forum</li>
                    <li>Hele Vejen</li>
                    <li>Hotel Randers</li>
                    <li>Kulbroen</li>
                    <li>This Festival</li>
                    <li>Creativity World Forum</li>
                    <li>Haven Festival</li>
                    <li>Kulturspot</li>
                </ul>
            </div>
        </div>
    </div>
  );
}

export default Projects;
