import React from 'react';
import './index.scss';

import {connect} from 'react-redux'
import {Animate} from 'react-move'
import BezierEasing from 'bezier-easing'

const DoTheScroll = (props) => {
	if (props.scrollIsAnimating) window.scroll(0, props.scrollPos)
	return null;
}

function AnimatedScrollHandler(props) {
  return (
  	<Animate
  		start={{scrollPos: 0}}
  		update={{
  			scrollPos: [props.scrollDest],
  			timing: {duration: props.scrollIsAnimating ? 1000 : 0, ease: BezierEasing(0.4,0,0.1,1)}
  		}}
  	>
  		{(state)=> (
  			<DoTheScroll scrollPos={state.scrollPos} scrollIsAnimating={props.scrollIsAnimating}/>
		)}
  	</Animate>
  );
}

export default connect((state) => ({
	scrollDest: state.App.scrollDest,
	scrollIsAnimating: state.App.scrollIsAnimating
}))(AnimatedScrollHandler);
